import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import AboutImage from "../components/about-image"
import TravelImage from "../components/travel-image"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <section className="intro">
      <div className="intro-container">
        <h2>About</h2>
        <p><strong>Preston “wexx” So</strong> (he/they) is the first Asian international fetish titleholder and the first Mr. International Rubber (MIR) of color. He is Mr. International Rubber 20 (2017) and Mr. New England Rubber 2016.</p>
      </div>
    </section>
    <section className="content">
      <div className="content-container">
        <AboutImage />
        <p><strong>Preston “wexx” So (he/they)</strong> is the first Asian international fetish titleholder and the first Mr. International Rubber (MIR) of color. He is Mr. International Rubber 20 (2017) and Mr. New England Rubber 2016. Preston’s writing has appeared in <a href="https://www.nytimes.com/2023/07/14/style/modern-love-latex-fetish-second-skin.html" target="_blank" rel="noopener noreferrer"><em>The New York Times</em></a> and <em>Mochi Magazine</em>, among other places, and he has been featured twice in <a href="https://www.vice.com/en/article/88xqdk/rubberists-are-starting-their-own-revolution" target="_blank" rel="noopener noreferrer"><em>Vice</em></a> in addition to <Link to="/press">media</Link> in three languages.</p>
        <p>Preston has been interested in latex rubber since 2007 and has actively practiced the fetish since 2010. He taught other students about leather, latex, neoprene, spandex, and other similar fetishes at <a href="http://www.hcs.harvard.edu/~munch/" target="_blank" rel="noopener noreferrer">Harvard College Munch</a>, the BDSM student organization at his alma mater, at a time when the group was publicly vilified by Bill O’Reilly and the Harvard College Republicans as <a href="https://video.foxnews.com/v/2001155426001#sp=show-clips" target="_blank" rel="noopener noreferrer">a university-funded “sex club” on Fox News</a>.</p>
        <p>Preston is a co-founder of the <a href="https://www.mrrubbermx.com/" target="_blank" rel="noopener noreferrer">Mr. Rubber Mexico</a> (est. 2017) and <a href="https://www.instagram.com/mrrubberbr/" target="_blank" rel="noopener noreferrer">Mr. Rubber Brazil</a> (est. 2019) contests, respectively the first rubber competitions in Latin America, and served as multilingual emcee for Mr. Rubber Mexico in 2018, 2021, 2022, and 2023; Mr. Rubber Poland in 2018; and Mr. Rubber Brazil in 2019. He also hosted the <a href="https://www.instagram.com/westcoastrubber/" target="_blank" rel="noopener noreferrer">West Coast Rubber Contest</a> in San Diego in 2023.</p>
        <p>As a globally recognized kink educator of color, Preston has given workshops at <a href="https://clawinfo.org/" target="_blank" rel="noopener noreferrer">Cleveland Leather Annual Weekend</a> (2017, 2018, 2019) and <a href="https://www.instagram.com/_purplepassion_/" target="_blank" rel="noopener noreferrer">DV8/Purple Passion</a> (“Latex Fetishism 101”) about latex care, latex fetish practices, and the experiences of people of color in the fetish community. He is also the founder of the <a href="https://asianleather.org" target="_blank" rel="noopener noreferrer">Asian Leather and Kink Alliance</a> and co-author of <a href="https://www.melbournerubber.com/diversity" target="_blank" rel="noopener noreferrer">Melbourne Rubber’s code of conduct and anti-harassment policy</a> in 2021, which has since been adopted by other fetish groups and events around the world.</p>
        <p>Representing fetishists of East Asian heritage, Preston judged the <a href="https://www.imrl.com" target="_blank" rel="noopener noreferrer">International Mr. Leather</a> (IML) 2019 and <a href="https://www.ipahw.com/" target="_blank" rel="noopener noreferrer">International Pup and Handler</a> (IPAHW) 2019 contests. Previously, he judged and keynoted <a href="https://www.olympusleather.com/" target="_blank" rel="noopener noreferrer">International Olympus Leather</a> 2018 in San Diego, where he spoke about the importance of visibility as a fetishist of color. He also gave the keynote at <a href="https://www.secrettalentofpets.com/" target="_blank" rel="noopener noreferrer">Secret Talent of Pets</a> 2020, where he shared his experiences with anti-Asian racism in the fetish community.</p>
        <p>During his title year as Mr. International Rubber 20, Preston evangelized latex fetishism in 13 countries and 24 cities and was the first MIR to visit Latin America and East Asia. Preston has worn latex in public regularly since 2015, especially where fetish gear is seldom seen, such as <a href="https://www.wearlatex.com/travelling-in-latex-tight-connection/" target="_blank" rel="noopener noreferrer">in flight</a>. Today, Preston still works to rubberize the world—to bring the latex fetish to places around the world and especially to unawakened rubberists. He is based in New York City.</p>
        <h3>Short bio</h3>
        <p><strong>Preston “wexx” So (he/they)</strong> is the first Asian international fetish titleholder and the first Mr. International Rubber (MIR) of color. He is Mr. International Rubber 20 (2017) and Mr. New England Rubber 2016. Preston’s writing has appeared in <a href="https://www.nytimes.com/2023/07/14/style/modern-love-latex-fetish-second-skin.html" target="_blank" rel="noopener noreferrer"><em>The New York Times</em></a> and <em>Mochi Magazine</em>, among other places, and he has been featured twice in <a href="https://www.vice.com/en/article/88xqdk/rubberists-are-starting-their-own-revolution" target="_blank" rel="noopener noreferrer"><em>Vice</em></a> in addition to <Link to="/press">media</Link> in three languages.</p>
        <p>Preston has been interested in latex rubber since 2007 and has actively practiced the fetish since 2010. He taught other students about leather, latex, neoprene, spandex, and other similar fetishes at <a href="http://www.hcs.harvard.edu/~munch/" target="_blank" rel="noopener noreferrer">Harvard College Munch</a>, the BDSM student organization at his alma mater, at a time when the group was publicly vilified by Bill O’Reilly and the Harvard College Republicans as <a href="https://video.foxnews.com/v/2001155426001#sp=show-clips" target="_blank" rel="noopener noreferrer">a university-funded “sex club” on Fox News</a>.</p>
        <p>Preston is a co-founder of the <a href="https://www.mrrubbermx.com/" target="_blank" rel="noopener noreferrer">Mr. Rubber Mexico</a> (est. 2017) and <a href="https://www.instagram.com/mrrubberbr/" target="_blank" rel="noopener noreferrer">Mr. Rubber Brazil</a> (est. 2019) contests, respectively the first rubber competitions in Latin America, and served as multilingual emcee for Mr. Rubber Mexico in 2018, 2021, 2022, and 2023; Mr. Rubber Poland in 2018; and Mr. Rubber Brazil in 2019. He also hosted the <a href="https://www.instagram.com/westcoastrubber/" target="_blank" rel="noopener noreferrer">West Coast Rubber Contest</a> in San Diego in 2023. Representing fetishists of East Asian heritage, Preston judged the <a href="https://www.imrl.com" target="_blank" rel="noopener noreferrer">International Mr. Leather</a> (IML) 2019 and <a href="https://www.ipahw.com/" target="_blank" rel="noopener noreferrer">International Pup and Handler</a> (IPAHW) 2019 contests. He is based in New York City.</p>
        <TravelImage />
        <p><Link to="/" className="call-to-action">Go back home</Link></p>
      </div>
    </section>
  </Layout>
)

export default AboutPage
